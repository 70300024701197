<!--
   Created by Terence on 2023/12/7 - 18:15
-->
<template>
  <div class="thumb" @click="handleClick">
    <div class="thumb-normal" v-if="myThumbAction === THUMB_ACTION.NORMAL">
      <span class="thumb-normal-item thumb-normal-up">
        <img
          class="thumb-normal-item-img"
          :src="
            'https://cdn.qiandaoapp.com/interior/images/87b6f57961651a51b9607e0eb2e4a17e.png' +
            '!mfit_w480_h480_png'
          "
          alt=""
        />
        {{ likeCount }}
      </span>
      <span class="splitline"></span>
      <span class="thumb-normal-item thumb-normal-down">
        <img
          class="thumb-normal-item-img"
          :src="
            'https://cdn.qiandaoapp.com/interior/images/87b6f57961651a51b9607e0eb2e4a17e.png' +
            '!mfit_w480_h480_png'
          "
          alt=""
        />
        {{ dislikeCount }}
      </span>
    </div>
    <div v-else class="thumb-clicked">
      <img
        :class="`thumb-clicked--${
          myThumbAction === THUMB_ACTION.LIKE ? 'up' : 'down'
        }`"
        :src="
          'https://cdn.qiandaoapp.com/interior/images/1f8bc7574b5ac29e0d9bf266de5306bc.png' +
          '!mfit_w480_h480_png'
        "
        alt=""
      />
      <span>{{
        myThumbAction === THUMB_ACTION.LIKE ? likeCount : dislikeCount
      }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";

const emit = defineEmits("onClickThumb");

const props = defineProps({
  myAction: {
    type: String,
    default: "normal",
  },
  likeCount: {
    type: Number,
    default: 0,
  },
  dislikeCount: {
    type: Number,
    default: 0,
  },
});

const THUMB_ACTION = {
  NORMAL: "normal",
  LIKE: "LIKE",
  DISLIKE: "DISLIKE",
};

const myThumbAction = computed(() => {
  if (props.myAction === -1) {
    return THUMB_ACTION.DISLIKE;
  } else if (props.myAction === 1) {
    return THUMB_ACTION.LIKE;
  } else {
    return THUMB_ACTION.NORMAL;
  }
});

const handleClick = () => {
  emit("onClickThumb");
};
</script>

<style lang="scss" scoped>
.thumb {
  &-normal {
    display: flex;
    padding: 16rpx 0;
    align-items: center;
    //width: 454rpx;
    width: 100%;
    border-radius: 16rpx;
    background: #f8f8f8;
    &-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.5);
      font-family: "Roboto";
      font-size: 28rpx;
      font-style: normal;
      font-weight: 700;
      line-height: 44rpx; /* 157.143% */
      &-img {
        margin-right: 8rpx;
        width: 32rpx;
        height: 32rpx;
      }
    }
    &-down {
      img {
        transform: rotate(180deg);
      }
    }
    .splitline {
      width: 2rpx;
      height: 24rpx;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  &-clicked {
    display: flex;
    padding: 16rpx 0;
    justify-content: center;
    align-items: center;
    border-radius: 16rpx;
    background: #7247dc;

    color: #fff;
    font-family: "Roboto";
    font-size: 28rpx;
    font-style: normal;
    font-weight: 700;
    line-height: 44rpx; /* 157.143% */
    img {
      margin-right: 8rpx;
      width: 32rpx;
      height: 32rpx;
    }
    &--down {
      transform: rotate(180deg);
    }
  }
}
</style>
