<!--
   Created by Terence on 2023/12/7 - 18:15
-->
<template>
  <div class="gridimgs" v-if="imgs.length">
    <van-image
      fit="cover"
      class="gridimgs-item"
      :src="item + '!mfit_w480_h480_jpg'"
      alt=""
      v-for="(item, index) in imgs"
      :key="index"
      @click="handlePreview(index)"
    />
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { Image as VanImage, showImagePreview } from "vant";

const props = defineProps({
  imgs: {
    type: Array,
    default: () => [],
  },
});

const handlePreview = (index) => {
  showImagePreview({
    images: props.imgs,
    startPosition: index,
  });
};
</script>

<style lang="scss" scoped>
.gridimgs {
  display: grid;
  grid-template-columns: repeat(3, 218rpx);
  grid-gap: 16rpx;
  &-item {
    width: 218rpx;
    height: 218rpx;
    border-radius: 8rpx;
    overflow: hidden;
  }
}
</style>
